import {endpoints} from "../api/endpoints";
import {api, logger} from "../app";
import Config from "../config";
import {intake} from "../containers/intake/intake";
import env from "../env";

const endpoint = endpoints[env.DEPLOYMENT];

export function useBase(base = {}, data = {}) {
  for (let i of Object.keys(base)) {
    if (typeof base[i] === "string") base[i] = "";
    else if (typeof base[i] === "number") base[i] = 0;
    else delete base[i];
  }

  return { ...base, ...data };
}

export function extractSurveyData(survey, surveyData = {}, base = {}) {
  // Add additional timing data:

  let data = {
    ...useBase(base, survey.data),
    ...surveyData,
    current_page: survey.currentPageNo,
  };
  survey.pages.forEach((page) => {
    if (page.name && page.timeSpent > 1) {
      data[`timer_${page.name}`] = page.timeSpent;
    }
  });

  return data;
}

export function setupLoadFromServer(model, formConfig) {
  model.onLoadChoicesFromServer.add((survey, options) => {
    Object.keys(formConfig).forEach((key) => {
      const question = survey.getQuestionByName(key);
      if (
        Array.isArray(options.choices) &&
        options.choices.length > 0 &&
        Array.isArray(options.serverResult[formConfig[key]])
      ) {
        question.choices = options.serverResult[formConfig[key]].map(
          (state) => state.Descripcion
        );
      }
    });
  });
}

export function updateDependentFields(model, mappings) {
  model.onValueChanged.add((survey, options) => {
    if (!options.question.choicesFromUrl) {
      return;
    }
    const {value, name} = options.question;
    const getOriginalItem = (mapping) => {
      return options.question.choicesFromUrl.find(choice =>
          choice.value === value && choice.originalItem && mapping.questionName === name)?.originalItem;
    };
    mappings.forEach(mapping => {
      const originalItem = getOriginalItem(mapping);
      if (!originalItem) {
        return;
      }
      mapping.questionsToClear.forEach(questionName => {

        survey.getQuestionByName(questionName).value = undefined;
      });
      survey.getQuestionByName(mapping.questionToSet).value = originalItem[mapping.property];
      // mapping.questionToSet.forEach(questionToSet => {
      //   survey.getQuestionByName(questionToSet).value = originalItem[mapping.property];
      // });
    });
  });
}

export function updateMatrixDependentFields(model, mappings) {
  model.onMatrixCellValueChanged.add((survey, options) => {
    const selectedOriginalItem = options.getCellQuestion(options.columnName)?.selectedItem?.originalItem;

    mappings.forEach(mapping => {
      if (mapping.questionName !== options.columnName || !selectedOriginalItem) {
        return;
      }
      const {questionToSet, property, questionsToClear} = mapping;
      const question = options.getCellQuestion(questionToSet);
      if (!question) {
        console.warn(`Question ${questionToSet} not found`);
        return;
      }
      const originalValue = selectedOriginalItem[property];

      questionsToClear.forEach(question => {
        options.getCellQuestion(question)?.clearValue();
      });

      question.value = originalValue;
    });
  });
}


export function clearDropdowns(model, formConfig) {
  // Config requires a dropdowns_to_clear item with an array of arrays for each container that has dropdowns that need to be cleared when parent dropdowns get changed.
  // "dropdowns_to_clear": {"creditCheck": [["home_country","custom_text_14","custom_text_28"],["home_city","po_box_postal_code","custom_text_29"]]}
  // Array needs to be ordered and grouped like the survey.
  // The array is sliced on the index + 1 of the current dropdown name, dropdowns matching an item in the sliced array will get cleared.
  model.onValueChanged.add((survey, options) => {
    try {
      for (const group of formConfig) {
        if (group.includes(options.name)) {
          for (const i of group.slice(group.indexOf(options.name) + 1)) {
            const question = survey.getQuestionByName(i);
            if (!question.value) return;
            question.value = undefined;
          }
        }
      }
    } catch (error) {
      console.error(`Error while clearing dropdowns ${error}`);
    }
  });
}

export function onUploadFiles(survey, options) {
  // options contains the file name, file and callback to store the correct url in the survey data that will be send
  let file = options.files[0];

  console.log("calling with: ", options);

  // check file type, and we only do it for larger files
  console.log("file: ", file);
  if (
    (file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg") &&
    file.size > 500000
  ) {
    _resizeImage(file, options.callback, options.force);
  } else {
    Config.upload_files_to_s3
      ? _uploadFileToS3(file, options.callback)
      : _uploadFileToServer(file, options.callback);
  }
}

function _fixToBlobCompat() {
  // toBlob function is not supported, so implement it
  if (!HTMLCanvasElement.prototype.toBlob) {  //IMPORTANT CHECK
    Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
      value: function (callback, type, quality) {
        var canvas = this;
        setTimeout(function () {
          var binStr = atob(canvas.toDataURL(type, quality).split(",")[1]),
            len = binStr.length,
            arr = new Uint8Array(len);

          for (var i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
          }

          callback(new Blob([arr], { type: type || "image/png" }));
        });
      },
    });
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function _resizeImage(file, callback, force = false) {
  /*
      Resizes image by translating it into a canvas, reducing the size till target, and turning back the url
      based on:
      - https://github.com/nicam/js-resize/blob/master/resize.js
      - https://stackoverflow.com/questions/6775767/how-can-i-draw-an-image-from-the-html5-file-api-on-canvas
   */

  try {
    logger.leaveBreadcrumb(
      "Reducing file:",
      { type: file.type },
      "force: ",
      force
    );

    let min_resolution = 1800;
    if (force) min_resolution = 1500; // just a bit smaller, but we try at least

    // load in file in fileReader, then onto image.
    let fr = new FileReader();
    fr.onload = function () {
      let img = new Image();
      img.onload = function () {
        // determine by landscape or portrait:
        let target_height = 0;
        let target_width = 0;
        if (img.height > img.width) {
          // portrait mode:

          // determine smallest factor, to shrink towards 500 kb, plus some margin
          target_height = img.height * (450000 / file.size);
          console.log("target is: ", target_height);
          // We maintain a minimum height
          if (target_height < min_resolution) target_height = min_resolution;

          // Then we calculate target width based on shrinkage ratio
          target_width = img.width * (target_height / img.height);
          logger.leaveBreadcrumb("Changing portrait image", {
            height: img.height,
            width: img.width,
            target_height: target_height,
            target_width: target_width,
          });
        } else {
          // Landscape mode:

          // determine smallest factor, to shrink towards 500 kb, plus some margin
          target_width = img.width * (450000 / file.size);
          console.log("target is: ", target_width);
          // We maintain a minimum height
          if (target_width < min_resolution) target_width = min_resolution;

          // Then we calculate target width based on shrinkage ratio
          target_height = img.height * (target_width / img.width);
          logger.leaveBreadcrumb("Changing landscape image", {
            height: img.height,
            width: img.width,
            target_height: target_height,
            target_width: target_width,
          });
        }

        // Finally, we draw image on canvas
        let mainCanvas = document.createElement("canvas");
        mainCanvas.width = target_width;
        mainCanvas.height = target_height;
        logger.leaveBreadcrumb("Creating canvas succeeded");

        let ctx = mainCanvas.getContext("2d");
        ctx.drawImage(img, 0, 0, target_width, target_height);
        logger.leaveBreadcrumb("Drawing canvas succeeded");

        // and we export the data
        _fixToBlobCompat();  //IMPORTANT CHECK
        mainCanvas.toBlob((blob) => {
          logger.leaveBreadcrumb("Succeeded create blob");

          // File() ctor compat. since File extends Blob, Blob with added props will work on older browsers
          let newFile = new Blob([blob], { type: file.type });
          newFile.name = file.name;

          // call itself again to see if the file now has reduced sufficiently
          if (!force) {
            // try first to see if the file is now correctly made smaller
            onUploadFiles(null, {
              callback: callback,
              files: [newFile],
              force: true,
            });
          } else {
            // too bad, we tried to make it smaller once, but we can't go any further
            Config.upload_files_to_s3
              ? _uploadFileToS3(newFile, callback)
              : _uploadFileToServer(newFile, callback);
          }
        });
      };

      img.src = fr.result;
      //logger.captureMessage("Image loaded");
    };
    fr.readAsDataURL(file);
  } catch (e) {
    console.log("error making file smaller:", e);
    logger.captureException(new Error("File reduce size error"));
    return file;
  }
}

export function _uploadFileToS3(file, callback) {
  // Function that is called after upload original or refactored file
  try {
    api
      .post("auth/sign_file.json", {
        file_name: file.name,
        file_type: file.type,
      })
      .then((data) => {
        if (data.signed_url) {
          fetch(data.signed_url, {
            method: "PUT",
            headers: new Headers({ "Content-Type": file.type }),
            body: file,
          })
            .then((response) => {
              // callback is done to ensure that the correct file_url is saved in the survey model data
              callback(data.signed_url);
            })
            .catch((error) => {
              _onUploadFileError(
                error,
                callback,
                "error uploading file to aws"
              );
            });
        }
      })
      .catch((error) => {
        _onUploadFileError(error, callback, "error signing file");
      });
  } catch (error) {
    _onUploadFileError(error, callback, "error signing file");
  }
}

export function _uploadFileToServer(file, callback) {
  try {
    let formdata = new FormData();
    formdata.append("file", file, file.name);

    let headers = api.buildHeaders();
    headers.delete("Content-Type");

    fetch(`${endpoint.api_url}/upload_file/`, {
      method: "PUT",
      headers: headers,
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        // callback is done to ensure that the correct file_url is saved in the survey model data
        callback(data.signed_url);
      })
      .catch((error) => {
        _onUploadFileError(error, callback, "error uploading file to server");
      });
  } catch (error) {
    _onUploadFileError(error, callback, "error signing file");
  }
}

export function getApiUrl(props) {
  const endpoint = endpoints[env.DEPLOYMENT];
  return props.device.api_service_tokens.address_service_url
    ? props.device.api_service_tokens.address_service_url
    : endpoint.api_url;
}

export function _uploadBase64ToServer(blob, file_name, file_type, callback) {
  // upload signature file to server
  try {
    let formdata = new FormData();
    formdata.append("file", blob, file_name);

    let headers = api.buildHeaders();
    headers.delete("Content-Type");

    fetch(`${endpoint.api_url}/upload_file/`, {
      method: "PUT",
      headers: headers,
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        // callback is done to ensure that the correct file_url is saved in the survey model data
        callback([{ name: data.signed_url, type: file_type }]);
      })
      .catch((error) => {
        _onSaveBase64Error(
          error,
          "error uploading file to server",
          callback,
          file_type
        );
      });
  } catch (error) {
    _onSaveBase64Error(
      error,
      "error uploading file to server",
      callback,
      file_type
    );
  }
}

export function _onUploadFileError(error, callback, message) {
  console.log(message, error);
  logger.leaveBreadcrumb(message);
  logger.captureException(new Error("File reduce size error"));
  callback(null);
}

export function saveBase64(file_name, file_type, base64, callback) {
  try {
    // first translates file from base64 to file
    // add no cache header to avoid CORS errors when base64 is a S3 url
    fetch(base64, {headers: {'Cache-Control': 'no-cache'}})
      .then((res) => res.blob())
      .then((blob) => {
        // Saves file and returns format in line with file format

        if (Config.upload_files_to_s3) {
          api
            .post("auth/sign_file.json", {
              file_name: file_name,
              file_type: file_type,
            })
            .then((data) => {
              if (data.signed_url) {
                fetch(data.signed_url, {
                  method: "PUT",
                  headers: new Headers({ "Content-Type": file_type }),
                  body: blob,
                })
                  .then((response) => {
                    callback([{ name: data.signed_url, type: file_type }]);
                  })
                  .catch((error) => {
                    _onSaveBase64Error(
                      error,
                      "error uploading file to aws",
                      callback,
                      file_type
                    );
                  });
              }
            })
            .catch((error) => {
              _onSaveBase64Error(
                error,
                "Signature Blob start upload error",
                callback,
                file_type
              );
            });
        } else {
          _uploadBase64ToServer(blob, file_name, file_type, callback);
        }
      })
      .catch((error) => {
        _onSaveBase64Error(
          error,
          "Signature Blob fetch base64 blob error",
          callback,
          file_type
        );
      });
  } catch (error) {
    _onSaveBase64Error(
      error,
      "Signature Blob reduce size error",
      callback,
      file_type
    );
  }
}

function _onSaveBase64Error(error, description, callback, file_type) {
  console.log("error signing file", error);
  logger.leaveBreadcrumb("Error signing signature blob");
  logger.captureException(new Error(description));
  callback([{ name: "", type: file_type }]);
}

export function get_informal_page_name(page) {
  // SurveyJS gets the formal name, but that is not saved in model, so convenience function to retrieve from index.
  return page ? intake.pages[page.visibleIndex].name : -1;
}

export function removeElementTitles(
  document,
  names = ["removeMe"],
  callback = (_el) => true
) {
  document.body.addEventListener(
    "DOMSubtreeModified",
    () => {
      Array.from(document.querySelectorAll("h5"))
        .filter((el) => names.indexOf(el.textContent) > -1)
        .forEach((el) => {
          if (callback(el)) {
            el.remove();
          }
        });
    },
    false
  );
}
export function select_survey_js_model(loan_purpose, products, purpose = null) {
  if (purpose) {
    return products[purpose] ? products[purpose] : products.default;
  } else {
    return products[loan_purpose] ? products[loan_purpose] : products.default;
  }
}
