import React from "react";
import {connect} from "react-redux";
import * as Survey from "survey-react";
import {Footer} from "../../components/footer/footer";
import HelpComponent from "../../components/help/help";
import {Start} from "../../components/start/start";
import Config from "../../config";
import {DEFAULT_LOCALE, translate} from "../../lib/intl";
import {loanActions} from "../../store/store";
import {calc_loanterms} from "../../survey/calculations";
import {Message} from "./../home/message";
import {
    clearDropdowns,
    getApiUrl,
    removeElementTitles,
    saveBase64,
    setupLoadFromServer,
    updateDependentFields,
    useBase,
} from "../../survey/survey.utils";
import {cooperative_bll} from "./cooperative_bll";
import {cooperative_rwanda} from "./cooperative_rwanda";
import {cooperative_rwanda_direct_model} from "./cooperative_rwanda_direct_model";
import {agreement, guarantor} from "./guarantor";
import "./guarantor.container.scss";
import {guarantor_crediavance} from "./guarantor_crediavance";
import {guarantor_credicapital} from "./guarantor_credicapital";
import {guarantor_ffdl} from "./guarantor_ffdl";
import {guarantor_finadev} from "./guarantor_finadev";
import {guarantor_procredito} from "./guarantor_procredito";
import {guarantor_rentamigo} from "./guarantor_rentamigo";
import {guarantor_intake_banco_popular} from "./guarantor_intake_banco_popular";
import env from "../../env";
import {addCURPButton} from "../../survey/curp";
import {guarantor_demo} from "./guarantor_demo";

const CURRENT_CONTAINER = "guarantor";

class GuarantorContainer extends React.PureComponent {
    // eslint-disable-next-line
    constructor(props) {
        super(props);

        const deploymentModels = {
            banco_popular: guarantor_intake_banco_popular,
            bll: cooperative_bll,
            crediavance: guarantor_crediavance,
            credicapital: guarantor_credicapital(getApiUrl(props)),
            demo: guarantor_demo,
            ffdl: guarantor_ffdl,
            finadev: guarantor_finadev,
            mfw: guarantor,
            procredito: guarantor_procredito(getApiUrl(props)),
            rentamigo: guarantor_rentamigo,
            rw: cooperative_rwanda,
            rwanda_direct_model: cooperative_rwanda_direct_model            
        };

        // setup model
        const currentDeploymentModel = (locale = DEFAULT_LOCALE) => {
            const model =
                deploymentModels[Config.deployment] !== undefined
                    ? deploymentModels[Config.deployment]
                    : deploymentModels.mfw;
            model.locale = locale;
            return model;
        };

        this.model = new Survey.Model(currentDeploymentModel(props.device.locale));

        if (
            Config.load_from_api &&
            Config.server_form_load &&
            Config.server_form_load[CURRENT_CONTAINER]
        ) {
            setupLoadFromServer(  //TODO CHECK IF THIS IS NEEDED
                this.model,
                Config.server_form_load[CURRENT_CONTAINER]
            );
        }

        if (
            Config.dropdowns_to_clear &&
            Config.dropdowns_to_clear[CURRENT_CONTAINER]
        ) {
            clearDropdowns(this.model, Config.dropdowns_to_clear[CURRENT_CONTAINER]);
        }

        const dependentQuestions = Config.dependent_questions?.[CURRENT_CONTAINER];
        if (dependentQuestions) {
            updateDependentFields(this.model, dependentQuestions)
        }

        // TODO: remodel signature object without file
        let data = this.props.loan.intake_guarantor;

        // add in beneficiary first_name and the loan terms
        data["user_first_name"] = this.props.loan.first_name;
        data["applicant_id_number"] = this.props.loan.id_number;
        data["applicant_phone"] = this.props.loan.phone_number;
        data["applicant_nationality"] = this.props.loan.nationality;
        data["amount"] = this.props.loan.amount;
        data["repayment_period"] = this.props.loan.repayment_period;
        data["monthly_insurance"] = this.props.loan.monthly_insurance;
        data["monthly_installment"] = this.props.loan.monthly_installment;
        data["total_fees"] = this.props.loan.total_fees;
        data["product_interest_rate"] = this.props.loan.loan_terms_summary?.product_interest_rate ?? '';
        data["admin_fee"] = this.props.loan.loan_terms_summary?.charge_dict.charge_code_1?.percentage ?? '';
        data["vat_on_admin_fee"] = this.props.loan.loan_terms_summary?.charge_dict.charge_code_2?.description ?? '';
        data["insurance_fee"] = this.props.loan.loan_terms_summary?.charge_dict.charge_code_3?.percentage ?? '';

        // make loan terms available in intake
        let loan_terms_summary = this.props.loan.loan_terms_summary
            ? this.props.loan.loan_terms_summary
            : {};

        this.checkIfRemoveSignature(data);

        const intake = this.props.loan.intake_guarantor;
        this.model.data = this.base = {
            ...this.model.data,
            ...data,
            loan_purpose: this.props.loan.loan_purpose,
            loan_terms_summary,
            intake,
            cooperative: this.props.loan.cooperative
        };

        try {
            this.model.currentPageNo = this.props.loan.intake_guarantor.current_page;
        } catch (error) {
            console.error(
                "Sometimes setting the page fails, so starting on title due to error: ",
                error
            );
        }

        // set callbacks
        this.model.onPartialSend.add((survey) => this.onPartialSend(survey));
        this.model.onCompleting.add((survey) => this.onCompleting(survey));

        this.model.render();

        this.state = {
            is_started: false,
            help_count: 0,
            help_time: 0,
            timeOpen: null,
        };


        try {
            if (env.DEPLOYMENT_NAME.indexOf("credicapital") > -1) {
                this.model.onAfterRenderPage.add(addCURPButton(this.props.device.api_service_tokens["address_service_token"],
                    getApiUrl(props), '[data-name="custom_text_108"] input', "[data-name='guarantor_first_name'] input",
                    "[data-name='guarantor_last_name'] input", "[data-name='guarantor_mother_name'] input",
                    "[data-name='custom_text_57'] input", "[data-name='gender'] input",
                    "[data-name='date_of_birth'] input"));
            }
        } catch (e) {
            console.error(e, "Error adding curp button");
        }


        this.header = "";
        try {
            let partner_number = undefined;
            if (this.model.data.intake.custom_text_127) {
                partner_number = this.model.data.intake.custom_text_127;
            } else if (this.model.data.intake.custom_text_128) {
                partner_number = this.model.data.intake.custom_text_128;
            }

            this.header = (
                <h2 className="heavy bigSpacer">
                    {translate("start_guarantor.header", {
                        guarantor_first_name: this.model.data.intake.first_name
                            ? this.model.data.intake.first_name
                            : this.props.loan.guarantor_first_name,
                        partner_number,
                    })}
                </h2>
            );
        } catch (e) {
            console.error(`Unable to translate ${e}`);
        }
    }

    checkIfRemoveSignature(data) {
        // keep signature if it is configured, otherwise remove it
        if (!Config.guarantor_container || !Config.guarantor_container.keep_signature) {
            delete data.signature;
        }
    }

    onPartialSend(survey) {
        let data = {
            ...survey.data,
            current_page: survey.currentPageNo,
        };
        if (data.guarantor_refused) {
            this.props.updateGuarantor(this.props.loan.uuid, this.base, data, true);
        } else {
            this.props.updateGuarantor(this.props.loan.uuid, this.base, data, false);
        }
                // if custom_text_100 is empty raise error
        // if ((env.DEPLOYMENT_NAME.indexOf("credicapital") > -1 && this.model.currentPageNo === 2) && (this.model.data.custom_text_100 === "")) {
        //     setAlertDialog("Error", "custom_text_100 is empty");
        //     // logger.captureException("custom_text_100 is empty");
        //     throw new Error("custom_text_100 is empty");
        // }
    }

    onCompleting(survey) {
        let data = survey.data;
        // if available use loan terms from the backend
        let loan_terms_summary = this.props.loan.loan_terms_summary
            ? this.props.loan.loan_terms_summary
            : {};
        let loan_terms = {
            total_fees: 0,
            agreement: "",
            repayment_period: data.repayment_period,
            ...loan_terms_summary,
        };

        data.agreement = loan_terms.agreement;

        //calculate loan terms and set agrement for mfw, rwanda
        if (["mfw", "rw"].includes(Config.deployment)) {
            loan_terms = calc_loanterms([
                data.amount,
                data.repayment_period,
                data.monthly_insurance,
                this.props.loan.loan_purpose,
            ]);

            const pointsOfAgreement = agreement(
                loan_terms,
                data.last_name,
                "Review the loan and sign below",
                data.loan_purpose
            );
            data.agreement = `${pointsOfAgreement}`;
        }

        saveBase64(
            "signature.png",
            "image/png",
            data.signature,
            (signature_date) => {
                data["signature"] = signature_date;
                console.log("saving data:", data);
                this.props.updateGuarantor(this.props.loan.uuid, this.base, data, true);
            }
        );
    }

    componentDidMount() {
        removeElementTitles(document, ["removeMe"]);
    }

    _openHelp() {
        // counts and keeps track of time
        this.setState({
            help_count: this.state.help_count + 1,
            timeOpen: new Date(),
        });
        this.model.stopTimer();
    }

    _closeHelp() {
        // resets model timer
        this.setState({
            timeOpen: null,
            help_time:
                this.state.help_time +
                Math.ceil((new Date() - this.state.timeOpen) / 1000),
        });
        this.model.startTimer();
    }

    render = ({history, account, device, loan} = this.props) => (
        <div className="guarantorContainer">
            <HelpComponent
                onClose={() => this._closeHelp()}
                onOpen={() => this._openHelp()}
                type="guarantor"
                locale={device.locale}
            />

            {this.props.loan.intake_guarantor.guarantor_refused ? (
                <div>
                    {/* Page to be displayed when guarantor refused */}
                    <Message message="submitted_guarantor"/>
                </div>
            ) : this.state.is_started ? (
                <div className="centerWrapper">
                    <Survey.Survey model={this.model} locale={device.locale}/>
                </div>
            ) : (
                <div>
                    <Start
                        stage="guarantor"
                        time={Config.has_no_container_time ? "0" : "10"}
                        onStart={() => this.setState({is_started: true})}
                    >
                        {this.header}

                        <p className="heavy">
                            {translate("start_guarantor.desc1", {
                                first_name: loan.first_name,
                                amount: loan.amount,
                            })}
                        </p>
                        <p className="spacer">{translate("start_guarantor.desc2")}</p>
                        <p className="spacer">{translate("start_guarantor.desc3")}</p>
                    </Start>
                    <Footer/>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        account: state.account,
        device: state.device,
        loan: state.loan,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGuarantor: (uuid, base, guarantor_intake, is_completed) =>
            dispatch(
                loanActions.updateGuarantor(
                    uuid,
                    useBase(base, guarantor_intake),
                    is_completed
                )
            ),
        setPage: (page) => dispatch(loanActions.setPage(page)),
    };
};

const connectedContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GuarantorContainer);

export {connectedContainer as GuarantorContainer};
